@import "../../styles/variaveis";

.menuMobile {
  ul {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    li{
  
      width: 200px;
    }
  }
}

.menuItens {
  display: flex;
  justify-content: space-between;
  width: 100%;
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    // justify-content: space-between;
    li {
      list-style: none;
      margin: 4px 0px 0px 0px;
      float: left;
      a {
        display: block;
        font-size: 14px;
        color: $cor-dark-4 ;
        text-decoration: none;
        padding: 10px 10px 5px 10px;
        margin: 0 10px;
        font-weight: bold; 
        &:hover {
          color: $cor-gray-40;
        }
      }

      &.active {
        a {
          color: $cor-light;
          border-bottom: 3px solid $cor-principal-light;
        }
      }
    }
  }
}
div{
  li{
    
      color: $cor-light ;

     
  }
}

@media (max-width: $brackpoint-md) {
  .menuItens {
    ul {
      li {
        margin: 10px 0px 0px 0px;
        a {
          padding: 10px 10px 5px 10px;
          margin: 0 4px;
        }
      }
    }
  }
}





@media (max-width: 1210px) {
  .menuDesktopLogado {
    display: none !important;
  }

  .menuMobileExternoLogado {
    display: block !important;
  }
}


@media (max-width: 780px) {
  .menuDesktopDeslogado {
    display: none !important;
  }

  .menuMobileExternoDeslogado {
    display: block !important;
  }
}

@media (max-width: 1210px) {
  .menuDesktopLogado {
    display: none !important;
  }

  .menuMobileExternoLogado {
    display: block !important;
  }
}

.menuMobileExternoLogado,.menuMobileExternoDeslogado {
  display: none;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 100px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #AA7F00;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #232406;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 240px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #c99300;
}
.bm-cross-button {
  left: 5px;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu { 
  background: #000000; 
  font-size: 1.15em; 
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #002fff;
}
// *{
//   border: dotted rgba(255, 255, 255, 0.243) 1px;
// }
/* Wrapper for item list */
.bm-item-list {
  color: #adadad;
  padding: 0.8em;
 
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(255, 0, 0);
  position: fixed;
  top: 0; 
  right: 0;
}
