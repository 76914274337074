@import '../../styles/variaveis.scss';

.titulo {
  text-align: center;
  font-size: 35px;
  margin-bottom: 3rem;
}

.span-1 {
  font-weight: bold;
  font-size: 20px;
}
.span-2 {
  font-size: 15px;
}
.span-3 {
  font-size: 11.5px;
}
.span-4 {
  font-weight: bold;
  font-size: 20px;
}
.span-5 {
  font-size: 15px;
}
.span-6 {
  font-size: 11.5px;
}

.botao-aq {
  background-color: rgb(88, 87, 87);
  border: none;
  color: #fefefe;
  border-radius: 32px;
  width: 12rem;
  padding: 6px;
  text-align: center;
  margin-right: 80rem;
}
.img-render {
  margin-left: 2rem;
  cursor: default;
  background: none;
  margin-top: 1rem;
}

.carregar-arquivos {
  cursor: pointer;
}

.botaoAnexar {
  display: flex;
  background-color: transparent;
  border: solid;
  height: 300px;
 }

.imgIconeAnexar{
  cursor: pointer;
  font-weight: 900;
  margin-top: 15px;
  font-size: 25px;
  color: $cor-principal-light-2;
}


.botao-enviar {
  background: rgb(126, 125, 125);
  border: 1px solid rgb(126, 125, 125);
}
.lupaPesquisarNF {
  position: absolute;
  right: 0;
  width: 39.2px;
  cursor: pointer;
}
