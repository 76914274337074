@import '../../styles/variaveis.scss';

.tituloComoFunciona {
    margin-bottom: 1.5em;
}
p {
    font-size: 18px;
    a{
        color: $cor-gray-40; 
        &:hover {
            color: $cor-light;
        }
    }

}

