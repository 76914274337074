@import '../../styles/variaveis';


header {
    background-color: $cor-dark-2;
    display: block;
    width: 100%;
    min-height: 54px;
    .logoCompany {
        height: 45px;
        margin: 16px 0px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

@media (max-width: $brackpoint-md) {
    header {
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;
    }
}









