@import '../../styles/variaveis';

.bgMainFeature {
    text-align: center;
    background-color: $cor-dark-2;
    color: #ffffff;
    img {
        margin: auto;
    }
}

.textDeco{ 
    text-Decoration: none !important;
    color: white !important; 
}

.bgFeature {
    padding: 40px 0px ;
    background-color: #1a1a1a;
    color: #ffffff;
}

.titleHome {
    text-align: left;
    margin-top: 30px;
    span {
        border: 1px solid $cor-principal;
        display: block;
        height: 10px;
        margin-bottom: 40px;
        width: 70%;
    }
}

.Redirecionar {
    background-color: #E6AC39;
    width: 300px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    padding-top: 6px;
    font-family: "Roboto", "Helvetica";
    font-size: 18px;
    font-weight: bold;
    margin: auto;
    margin-bottom: 60px;
}

.bgFeature2 {
    padding: 12px 0px;
    background: linear-gradient(to right, #4C4C4C 0%, #4C4C4C 0%, #4C4C4C 55%, #3F3F3F 51%);
    color: #ffffff;
}

.bgFeature3 {
    padding: 12px 0px;
    background: linear-gradient(to right, #4C4C4C 0%, #4C4C4C 0%, #4C4C4C 59.5%, #3F3F3F 51%);
    color: #ffffff;
}