.botao {
    text-align: center;
    padding: 5px;
    margin-right: 10px;
    margin-top: 7.5rem;
    width: 15rem;
}

.titulo {
    text-align: center;
    font-size: 35px;
    margin-bottom: 3rem;
}

.span-1 {
    font-weight: bold;
    font-size: 20px;
}
.span-2 {
    font-size: 15px;
 }
 .span-3 {
    font-size: 11.5px;
 }
 .span-4 {
    font-weight: bold;
    font-size: 20px;
}
.span-5 {
    font-size: 15px;
 }
 .span-6 {
    font-size: 11.5px;
 }

.botao-aq {
    background-color: rgb(88, 87, 87);
    border: none;
    color: #fefefe;
    border-radius: 32px;
    width: 12rem;
    padding: 6px;
    text-align: center;
    margin-right: 80rem;
}
.img-render {
    margin-left: 2rem;
    cursor: default;
    background: none;
    margin-top: 1rem;

}

.carregar-arquivos {
    cursor: pointer;
}


.botoes {
    text-align: center;
    padding: 5px;
    margin-right: 10px;
    margin-top: 7.5rem;
    width: 15rem;
}

.botao-enviar {
    background: rgb(126, 125, 125);
    border: 1px solid rgb(126, 125, 125);
}

.horizontal {
    width: 125px;
    border: 1px solid #E6AC39;
    margin-top: 15px;
    margin-bottom: 5px;
}

.tamanhoFonteNumero{
    font-size: 50px;
    font-weight: bold
 }

 .tamanhoFontePontos{
    font-size: 12px;
 }

.saldo {
    margin-top: 15px;
}

.tamanhoFontePontosTitulo{
    font-size: 20px;
}