@import "variaveis";

.btn {
  border-radius: 0px;
}

.btn-info {
  background-color: $cor-gray-40;
  border-color: $cor-gray-40;
  &:focus,
  &:hover {
    background-color: $cor-gray-60;
    box-shadow: none;
  }
}
body{overflow: hidden !important;}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: $cor-gray-40;
  border-color: $cor-gray-40;
}

.btn-primary {
  background-color: #3ff0ed;
  border: #000  4px solid !important;
  border-width: 1px;
  font-weight: bold;
  &.btn-lg {
    font-size: 16px;
    padding: 14px 40px;
  }
  &:focus,
  &:active,
  &:hover {
    background-color: #9df8f7;
    border-color: #9df8f7;
    box-shadow: none;
    color: #000 !important;
  }
  &:disabled {
    background-color: #aaa !important;
    border: none;
  }
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #aaa;
  background-color: #3ff0ed;
  border-color: #3ff0ed;
}

.btn-outline-primary {
  border-color: #3ff0ed;
  color: $cor-light;
  border-width: 2px;
  font-weight: bold;
  &.btn-lg {
    font-size: 22px;
    padding: 14px 50px;
  }
  &:hover,
  &:hover:focus {
    background-color: transparent;
    color: $cor-light-2;
    border-color: #3ff0ed;
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    border-color: $cor-light-2 !important;
    color: $cor-light !important;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #3ff0ed;
  color: $cor-dark;
}

.btn-link {
  color: $cor-dark;
  text-decoration: underline;
  font-weight: bold;

  img {
    margin-right: 10px;
    width: 20px;
    margin-bottom: 6px;
  }

  &:hover {
    color: $cor-dark;
    background-color: $cor-gray-20;
  }
}
