.horizontal {
    width: 125px;
    border: 1px solid #E6AC39;
    margin-top: 15px;
    margin-bottom: 5px;
}

.tamanhoFonteNumero{
    font-size: 50px;
    font-weight: bold
 }

 .tamanhoFontePontos{
    font-size: 12px;
 }

.saldo {
    margin-top: 15px;
}