.botaoFechar {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px !important;
  border: solid white 1px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
}

.tudo {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
}
.back {
  width: 100%;
  opacity: 0.2;
}

// *{border: dotted 1px rgba(255, 255, 255, 0.376);}
.legendaRoleta {
  display: flex;
  justify-content: center;
  margin: 0 !important;
  text-align: center;
  padding: 10px;
  background-color: #3ff0ed;
  font-size: 20px;
  color: #000;
  width: 100%;
}

.divRoleta {
  position: absolute;
  width: 70%;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 2vw;
  overflow: hidden;
}
 
.roleta {
  width: 100%;
  // filter: hue-rotate(45deg) !important;
}

.botaoJogar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80vw;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  font-size: clamp(1rem, 1.5vw, 5rem);
  padding: 10px 30px !important;
  border-radius: 5vw;
  color: black;
  border-color: #fff 3px solid !important;
}

// .botaoJogar {
//   position: absolute;
//   top: 50%;
//   left: 33%;
//   z-index: 20;
//   width: 10%;
//   cursor: pointer;
// }

.seta {
  position: absolute;
  top: 34%;
  left: 7%;
  z-index: 20;
  width: 15%;
  filter: hue-rotate(30deg); 
}

.girar {
  transform: rotate(3600deg);
  animation: identifier 5s ease-in-out;
}

@keyframes identifier {
  0% {
    transform: rotate(0deg);
  }

  50% {
    filter: blur(3px);
  }
}

.girar45 {
  transform: rotate(3600deg);
  animation: identifier45 5s ease-in-out;
}

@keyframes identifier45 {
  0% {
    transform: rotate(45deg);
  }

  50% {
    filter: blur(3px);
  }
}
