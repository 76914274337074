@import '../../styles/variaveis.scss';

.PaginationContainer {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;

    .PagesAction {
        display: flex;
        gap: 20px;
        align-items: center;

        .controlPage { 
            padding: 5px 10px;
            cursor: pointer;
            border: 2px solid transparent;
            font-weight: bold;
            &:hover {
                border: 2px solid #f4f4f4;
                background-color: #f4f4f4;
            }
            &.disabled {
                color: #cccccc;
                cursor: not-allowed;
            }
        }

        .numerPage {
            padding: 5px 10px;
            border: 2px solid $cor-principal;
            font-weight: bold;
        }
    }

    
}