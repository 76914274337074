@import '../../styles/variaveis';

.banner {
    position: relative;
    background-position: center center;
    background-size: cover;
    padding: 40px;
    margin-bottom: 60px;

    .bannerContent {
        margin: auto;
        border: 4px solid $cor-principal-light;
        display: block;
        min-height: 300px;
        max-width: 1200px;
        padding: 40px;
        display: flex;
        align-items: center;
        img {
            max-width: 100%;
        }
    }
}
.banner.car {
    .bannerMainImg {
        display: block;
        position: absolute;
        width: 600px;
        bottom: -100px;
        right: 0px;
    }
    .imgMd { display: none;}
}

.banner.phone {
    .bannerMainImg {
        display: block;
        position: absolute;
        max-width: 240px;
        top: 30px;
        left: 66%;
    }
    .imgMd { display: none;}
}

@media (max-width: $brackpoint-md) {
    .banner {
        .bannerContent {
            align-items: center;
        }
    }
    .banner.car {
        .bannerContent {
            align-items: start;
        }
        .bannerMainImg {
            width: 88%;
            bottom: -120px;
            right: 0px;
        }
    }
    
    .banner.phone {
        .bannerMainImg {
            width: 88%;
            max-width: 220px;
            bottom: -100px;
            left: unset;
            right: 6%;
        }
        .img { display: none;}
        .imgMd { display: block; max-width: 240px;}
    }
}

@media (max-width: $brackpoint-sm) {
    .banner {
        margin-bottom: 100px;
        .bannerContent {
            padding: 30px;
            align-items: start;
        }
    }
 
    .banner.car {
        .bannerMainImg {
            width: 98%;
            top: 230px;
            right: 0px;
        }
    }
    
    .banner.phone {
        .bannerMainImg {
            width: 180px;
            right: unset;
            top: 180px;
            margin-left: calc(50% - 120px);
        }
        .img { display: block;}
        .imgMd { display: none; max-width: 240px;}
    }
}



@media (max-width: $brackpoint-xs) {
    .banner.phone {
        .bannerMainImg {
            width: 180px;
            right: unset;
            top: 180px;
            margin-left: calc(50% - 120px);
        }
        .img { display: none;}
        .imgMd { display: block; max-width: 100%;}
    }
}