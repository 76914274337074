@import '../../styles/variaveis.scss';

.containerUpload {

    input[type='file'] {
        display: none
    }
    
    label {
        &.block {
            width: 100%;
            text-align: center;
        }
        &.file {
            background-color: $cor-principal;
            border-radius: 8px;
            color: #fff;
            cursor: pointer;
            padding: 8px 16px;
            text-transform: uppercase;
            font-size: 0.875em;
            font-weight: 500;

            &:hover {
                background-color: #2a7e5c !important;
            }
        }
        &.large {
            padding: 12px 16px !important;
        }
    }
    
}

.MuiDialog-root {
    &.card {
        .MuiPaper-root.MuiDialog-paper { 
            border-left: 10px solid $cor-gray-50;
        }
    }
}

.modalCard {
    .colorDestaque {
        color: $cor-principal;
    }
    label {
        font-size: 12px;
        margin: 0px;
        font-weight: 600;
        &.destacar {
            font-size: 16px;
            color: $cor-gray-50;
        }
    }
    p {
        font-size: 16px;
        margin: 0px 0px 14px 0px;
        font-weight: 600;
        &.small {
            font-size: 12px;
            font-weight: normal;
        }
        &.destacar {
            font-size: 20px;
            color: $cor-gray-50;
        }
    }
}
