@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700;1,900&display=swap');

/////// variaveis
$cor-principal: #AA7F00;
$cor-principal-light: #CD9834;
$cor-principal-light-2: #E6AC39;

$cor-dark: #000000;
$cor-dark-1: #212121;
$cor-dark-2: #1B1B1B;
$cor-dark-3: #202020;
$cor-dark-4: #808080;

$cor-light: #ffffff;
$cor-light-2: #F8F8F8;
$cor-light-3: #F8F8F8;

$cor-gray-80: #4d4d4d;
$cor-gray-60: #707070;
$cor-gray-50: #A6A6A6;
$cor-gray-40: #BFBFBF;
$cor-gray-20: #E8E8E8;
$cor-gray-10: #F5F5F5;

$brackpoint-xs: 350px;
$brackpoint-sm: 576px;
$brackpoint-md: 768px;
$brackpoint-lg: 992px;
$brackpoint-sxlm: 1200px;

